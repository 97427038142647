/**
 * Configuration class for date formats.
 */
export class DatesConfig {
  /**
   * The format for displaying order dates.
   */
  static readonly ORDER_DATE_FORMAT = 'd MMMM y, HH:mm';

  /**
   * The format for displaying reservation dates.
   */
  static readonly RESERVATION_DATE_FORMAT = 'd MMMM y';

  /**
   * The date format used for booking tables.
   */
  static readonly BOOK_TABLE_DATE_FORMAT = 'yyyy-MM-dd';
  static readonly CREATED_AT_DATE_FORMAT = 'yyyy-MM-dd';

  /**
   * The format used for booking table hours.
   */
  static readonly BOOK_TABLE_HOUR_FORMAT = 'HH:mm:ss';

  /**
   * The format for displaying reservation hours.
   */
  static readonly RESERVATION_HOUR_FORMAT = 'HH:mm';
  /**
   * The time format used for displaying order times.
   */
  static readonly ORDER_TIME_FORMAT = 'HH:mm';

  /**
   * The format for displaying delivery times.
   */
  static readonly DELIVERY_TIME_FORMAT = 'mm';

  static readonly DATE_BIRTH_DATE_FORMAT = 'yyyy-MM-dd';
}
